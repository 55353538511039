<template>
  <ul class="game-list desktop">
    <li v-for="(game, index) of centerGames" :key="index" :class="{ 'game-list--top': game.class === 'final' }">
      <GameItem :game="game" class-name="favorite" />
    </li>
  </ul>
</template>
<script setup lang="ts">
import { HOME_SPORTS } from '~/resources/home'
const GameItem = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/game-center-item.vue'))
const centerGames = reactive(HOME_SPORTS)
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/game-center/list.scss"></style>
